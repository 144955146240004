import { DataGrid, PieChart } from "devextreme-react"
import { Column, Item, Summary, Toolbar, TotalItem } from "devextreme-react/data-grid"
import { Connector, Label, Legend, Series, Size } from "devextreme-react/pie-chart"
import React from "react"

export class Sales extends React.Component {
    constructor(props) {
        super(props)
        this.$ = props.parent
        this.fn = props.fn
        this.state = { ...props }
    }
    componentDidMount() {}
    onRefresh = () => {}
    render($, x) {
        // return <></>
        $ = this.$
        x = this.state.data
        return (
            <div className="row m-0">
                {$.info.access.includes("sales_by_customer") ? (
                    <div className="col-md-4">
                        <PieChart dataSource={x.sales_by_customer} title="Penjualan Per Customer" resolveLabelOverlapping="shift">
                            <Series argumentField="display" valueField="value">
                                <Label visible={true} customizeText={(e) => e.argumentText + "\r\n" + e.value.toLocaleString("en-US")}>
                                    <Connector visible={true} width={1} />
                                </Label>
                            </Series>
                            <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right" rowCount={2} visible={false} />
                        </PieChart>
                    </div>
                ) : (
                    <></>
                )}
                {$.info.access.includes("sales_by_item") ? (
                    <div className="col-md-4">
                        <PieChart dataSource={x.sales_by_item} title="Penjualan Per Item" resolveLabelOverlapping="shift">
                            <Series argumentField="display" valueField="value">
                                <Label visible={true} customizeText={(e) => e.argumentText + "\r\n" + e.value.toLocaleString("en-US")}>
                                    <Connector visible={true} width={1} />
                                </Label>
                            </Series>
                            <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right" rowCount={2} visible={false} />
                        </PieChart>
                    </div>
                ) : (
                    <></>
                )}
                {$.info.access.includes("sales_by_salesman") ? (
                    <div className="col-md-4">
                        <PieChart dataSource={x.sales_by_salesman.data} title={x.sales_by_salesman.title} resolveLabelOverlapping="shift">
                            <Series argumentField="display" valueField="value">
                                <Label visible={true} customizeText={(e) => e.argumentText + "\r\n" + e.value.toLocaleString("en-US")}>
                                    <Connector visible={true} width={1} />
                                </Label>
                            </Series>
                            <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right" rowCount={2} visible={false} />
                        </PieChart>
                    </div>
                ) : (
                    <></>
                )}
                {$.info.access.includes("sales_by_date") ? (
                    <div className="col-md-8">
                        <DataGrid
                            dataSource={x.sales_by_date.data}
                            paging={{ pageSize: 10 }}
                            searchPanel={{ visible: true }}
                            export={{ enabled: true }}
                            columnAutoWidth={true}
                        >
                            <Toolbar>
                                <Item location={"before"} name={"exportButton"} />
                                <Item location={"before"}>{x.sales_by_date.title}</Item>
                                <Item location={"after"} name={"searchPanel"} />
                            </Toolbar>
                            <Column dataField="id" sortOrder={"desc"} sortIndex={1} />
                            <Column dataField="date" dataType="date" format="dd MMM yyyy" groupIndex={0} sortOrder={"desc"} sortIndex={0} />
                            <Column dataField="code" />
                            <Column dataField="name" />
                            <Column dataField="salesman" />
                            <Column dataField="total" dataType="number" format="#,##0.##;-#,##0.##" />
                            <Column dataField="paid" dataType="number" format="#,##0.##;-#,##0.##" />
                            <Column dataField="discount" dataType="number" format="#,##0.##;-#,##0.##" />
                            <Column dataField="sisa" dataType="number" format="#,##0.##;-#,##0.##" />
                            <Summary>
                                <TotalItem
                                    column="total"
                                    summaryType="sum"
                                    valueFormat="#,##0.##;-#,##0.##"
                                    customizeText={(e) => (isNaN(e.value) ? 0 : (+e.value).toLocaleString("en-US"))}
                                />
                                <TotalItem
                                    column="paid"
                                    summaryType="sum"
                                    valueFormat="#,##0.##;-#,##0.##"
                                    customizeText={(e) => (isNaN(e.value) ? 0 : (+e.value).toLocaleString("en-US"))}
                                />
                                <TotalItem
                                    column="discount"
                                    summaryType="sum"
                                    valueFormat="#,##0.##;-#,##0.##"
                                    customizeText={(e) => (isNaN(e.value) ? 0 : (+e.value).toLocaleString("en-US"))}
                                />
                                <TotalItem
                                    column="sisa"
                                    summaryType="sum"
                                    valueFormat="#,##0.##;-#,##0.##"
                                    customizeText={(e) => (isNaN(e.value) ? 0 : (+e.value).toLocaleString("en-US"))}
                                />
                            </Summary>
                        </DataGrid>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        )
    }
}
