import { DropDownBox, FileUploader, Template } from "devextreme-react"
import DataGrid, { Editing, FilterPanel, FilterRow, MasterDetail, Paging, Scrolling, SearchPanel, Selection } from "devextreme-react/data-grid"
import React, { Suspense } from "react"
import { PostData } from "../../../Helper"
import {
    onRefresh,
    onExporting,
    onSaving,
    onSaved,
    onEditingStart,
    onInitNewRow,
    onContentReady,
    onToolbarDetail,
    onContentReadyDetail,
    onContentReadyPopup,
    onContentReadyPopupDetail,
    onSelectionChanged,
    onDetailStart,
    onExport
} from "./event"
import { settingColumn, settingDetail, settingEditing, settingSelection } from "./setting"
import { RenderPopupUser } from "./renderPopup"
import { BdoRenderPopupFollowUp } from "../custom/bdoFollowUp"

export const renderMain = ($, e) => {
    e = { form: $.state.form, popup: ($.state.config ?? {}).popup ?? [] }
    return $.state.form !== undefined && $.state.form !== null ? (
        <Suspense fallback={"Page '" + $.state.info.name + "' not found "}>
            <e.form $={$}></e.form>
        </Suspense>
    ) : $.state.form === undefined ? (
        <div>
            <span className="fa fa-circle-o-notch fa-spin"></span> Please wait ...
        </div>
    ) : (
        <>
            <RenderGridMain parent={$}></RenderGridMain>
            <RenderPopupUser parent={$}></RenderPopupUser>
            {e.popup.includes("BdoRenderPopupFollowUp") ? <BdoRenderPopupFollowUp parent={$}></BdoRenderPopupFollowUp> : <></>}
        </>
    )
}
export class RenderDefault extends React.Component {
    constructor(props) {
        super(props)
    }
    render($, selection, masterDetail) {
        return <></>
    }
}
export class RenderGridMain extends React.Component {
    constructor(props) {
        super(props)
    }
    render($, selection, masterDetail) {
        $ = this.props.parent
        selection = $.state.config.selection ?? { mode: "none" }
        masterDetail = $.state.config.masterDetail ?? { enabled: false }
        return (
            <DataGrid
                onInitialized={(e) => {
                    $.grid = e.component
                    settingEditing($)
                    setTimeout(() => onRefresh($))
                }}
                onContentReady={(e) => {
                    if ($.exporting !== undefined && $.exporting !== null) onExport($, $.exporting.e, $.exporting.x)
                }}
                onEditCanceled={(e) => onContentReady($)}
                columns={$.fn.column($.flag)}
                columnAutoWidth={true}
                columnFixing={{ enabled: true }}
                width={"100%"}
                height={"100%"}
                export={{ enabled: $.access.includes("export") }}
                // onToolbarPreparing={(e) => onToolbarPreparing($, e)}
                // toolbar={{ items: onToolbar($) }}
                keyExpr="id"
                onExporting={(e) => onExporting($, e)}
                onSaving={(e) => onSaving($, e)}
                onSaved={(e) => onSaved($, e)}
                onEditingStart={(e) => onEditingStart($, e)}
                onInitNewRow={(e) => onInitNewRow($, e)}
                onSelectionChanged={(e, x, a, b, i) => {
                    x = $.state.config ?? {}
                    b = {}
                    if (e.currentSelectedRowKeys.length > 0) {
                        a = e.currentSelectedRowKeys[0]
                        if (x.preventSelection !== undefined) {
                            b.data = e.selectedRowsData.filter((expr) => expr.id === a)
                            if (b.data.length > 0) {
                                b.data = b.data[0]
                                for (i in x.preventSelection)
                                    if (x.preventSelection[i] === b.data[i]) return $.grid.deselectRows(e.currentSelectedRowKeys)
                            }

                            // if (!is) break
                            // else is = expr[i] !== x.preventSelection[i]
                            // console.log(b.check)
                            // if (b.check.length <= 0) $.grid.deselectRows(e.currentSelectedRowKeys)
                        }
                        if (masterDetail.enabled) e.component.expandRow(a)
                    } else if (e.currentDeselectedRowKeys.length > 0) {
                        a = e.currentDeselectedRowKeys[0]
                    }
                    if (a !== undefined) onSelectionChanged($, a)
                }}
                // onCellPrepared={(e) => {
                //     if (e.column.type === "selection" && e.data !== undefined)
                //         if (e.data.detail === "invoice_espay_down_payment") window.$(e.cellElement).remove()
                // }}
            >
                <MasterDetail enabled={masterDetail.enabled} template="masterDetail" />
                <Selection mode={selection.mode} allowSelectAll={!masterDetail.enabled} />
                <FilterPanel visible={true} />
                <SearchPanel visible={true} placeholder="Search..." />
                <Editing allowDeleting={$.access.includes("delete")} allowUpdating={$.access.includes("edit")} useIcons={true} />
                <Template name="importExcel" render={(e) => renderUploadExcel($, e)} />
                <Template name="gridDetail" render={(e, index) => renderGridDetail($, e, index)} />
                <Template name="dropDown" render={(e, index) => renderDropDown($, e, index)} />
                <Template name="masterDetail" render={(e, index) => renderMasterDetail($, e, selection, index)} />
            </DataGrid>
        )
    }
}
export const renderDropDown = ($, p, x, i) => {
    x = { data: [...$.fn.data(p.data.table)] }
    if ($.filter[p.target] === undefined) $.filter[p.target] = p.multiple ? [] : null
    if (p.selection === undefined || p.selection === null) p.selection = "multiple"
    if (typeof p.data === "object") {
        if (p.data.selection !== undefined) p.selection = p.data.selection
        for (i in p.data.filter) {
            x.temp = p.data.filter[i]
            x.data = x.data.filter((expr) => {
                switch (x.temp[1]) {
                    case "=":
                        return expr[x.temp[0]] === x.temp[2]
                    case "<>":
                        return expr[x.temp[0]] !== x.temp[2]
                    default:
                        return true
                }
            })
        }
    }
    return (
        <DropDownBox
            valueExpr={p.data.valueExpr}
            deferRendering={false}
            displayExpr={p.data.displayExpr}
            placeholder={p.data.title}
            showClearButton={false}
            onContentReady={(e) => {
                p.object = e.component
                e.component.option("dataSource", x.data)
            }}
            dropDownOptions={{ width: 500 }}
            contentRender={(a) => {
                return (
                    <DataGrid
                        height={345}
                        width={"100%"}
                        columns={p.data.columns}
                        hoverStateEnabled={true}
                        keyExpr={p.data.valueExpr}
                        onContentReady={(e) => {
                            p.grid = e.component
                            e.component.option("dataSource", x.data)
                        }}
                        loadPanel={{ enabled: false }}
                        // selectedRowKeys={$.filter[p.target]}
                        onSelectionChanged={(e) =>
                            p.object.option("value", ($.filter[p.target] = (e.selectedRowKeys.length && e.selectedRowKeys) || []))
                        }
                    >
                        <Selection mode={p.selection} />
                        <Scrolling mode="virtual" />
                        <Paging enabled={true} pageSize={10} />
                        <FilterRow visible={true} />
                    </DataGrid>
                )
            }}
        />
    )
    // return (
    //     <DropDownBox
    //         value={this.state.gridBoxValue}
    //         valueExpr="id"
    //         deferRendering={false}
    //         displayExpr="name"
    //         placeholder="Select a value..."
    //         showClearButton={true}
    //         dataSource={this.state.loadcoa}
    //         onValueChanged={this.syncDataGridSelection}
    //         contentRender={this.dataGridRender}
    //     />
    // )
}
export const renderUploadExcel = ($, e) => {
    return (
        <FileUploader
            labelText=""
            selectButtonText="Import"
            multiple={false}
            allowedFileExtensions={[".xlsx"]}
            uploadMode={"instantly"}
            uploadUrl={window.url + $.fn.url("import")}
            style={{ marginTop: ".25rem" }}
            showFileList={false}
            onUploadError={(expr, message, x) => {
                x = {}
                message = "Sorry, there's something went wrong"
                if (typeof expr === "object") {
                    if (typeof expr.error === "object")
                        if (expr.error.responseText !== undefined && expr.error.responseText !== null && expr.error.responseText !== "") {
                            x.split = expr.error.responseText.split(":")
                            if (x.split.length > 1) {
                                x.split = x.split[1].split("in file")
                                if (x.split.length > 1) {
                                    message = x.split[0].trim()
                                }
                            }
                        }
                }
                window.toastr.error(message)
            }}
            onUploaded={(expr, x) => {
                try {
                    x = typeof expr.request.response === "string" ? JSON.parse(expr.request.response) : expr.request.response
                    if (x === "" || x === undefined || x === null) x = { code: 200, message: "" }
                    if (+x.code !== 200) {
                        window.toastr.show(x.message)
                        return
                    } else {
                        window.toastr.success(x.message)
                        onRefresh($)
                    }
                } catch (ex) {
                    window.toastr.error(ex.message)
                }
            }}
            uploadHeaders={{
                Authorization: "Bearer " + localStorage.getItem(window.token)
            }}
            className="hide-file-list"
            width={100}
        />
    )
}

export const renderGridDetail = ($, p, i, name, setting) => {
    name = p.name
    if (p.mode === undefined) p.mode = "popup"
    if (p.accesskey === undefined && p.shortcut !== undefined) p.accesskey = p.shortcut
    if (p.accesskey === undefined) {
        p.accesskey = "n"
        p.css = "detail"
    } else {
        p.accesskey = p.accesskey.toLowerCase()
        p.css = "detail-" + p.accesskey
    }
    if (p.number === undefined || p.number === null) p.number = "number"
    if (p.keyExpr === undefined) p.keyExpr = p.number
    setting = { ...p }
    setting.id = $.old.key
    if (p.onSaved !== undefined) if (typeof p.onSaved === "string") p.onSaved = [p.onSaved]
    return (
        <DataGrid
            paging={{ enabled: false }}
            width={"100%"}
            onInitialized={(e) => {
                settingDetail($, setting, e.component)
                // $.grid.refresh()
            }}
            onContentReady={(e) => onContentReadyDetail($, p)}
            columns={$.fn.detail($.flag + "_" + name)}
            loadPanel={{ enabled: false }}
            repaintChangesOnly={false}
            onSaving={(e, grid, x, i) => {
                grid = $.details[name].grid
                x = { groupColumn: grid.option("columns").filter((expr) => (expr.groupIndex ?? -1) >= 0) }
                for (i in x.groupColumn) grid.columnOption(x.groupColumn[i].dataField, "groupIndex", undefined)
            }}
            onSaved={(e, x, i, j, k) => {
                settingDetail($, { name: name, number: p.number }, e.component)
                if (p.onSaved !== undefined)
                    for (j in p.onSaved) {
                        x = {}
                        if (typeof p.onSaved[j] === "object")
                            for (k in p.onSaved[j])
                                switch (k) {
                                    case "cost":
                                        x.target = p.onSaved[j][k]
                                        x.cost = 0
                                        x.subtotal = +($.grid.cellValue($.old.index, "subtotal") ?? 0)
                                        for (j in $.details[x.target].dataSource) {
                                            x.row = $.details[x.target].dataSource[j]
                                            x.cost += +(x.row.nominal ?? 0)
                                        }
                                        x.totalDetail = +x.subtotal + +x.cost
                                        $.grid.beginUpdate()
                                        $.grid.cellValue($.old.index, "cost", +x.cost)
                                        $.grid.cellValue($.old.index, "total_detail", x.totalDetail)
                                        $.grid.endUpdate()
                                        break
                                }
                        else
                            switch (p.onSaved[j]) {
                                case "force":
                                    $.grid.cellValue($.old.index, "force", -($.grid.cellValue($.old.index, "force") ?? 1))
                                    break
                                case "set_price_with_cogs":
                                    // for(k in e.changes){
                                    //     x = $.details[name].filter((expr)=>expr[p.number] === )

                                    // }
                                    // console.log(e)
                                    // console.log($.details[name])
                                    break
                                default:
                                    eval(p.onSaved[j])
                                    break
                            }
                    }

                for (i in $.selectedGroup)
                    if ($.selectedGroup[i].item === undefined || $.selectedGroup[i].item === null)
                        $.selectedGroup[i].object.option("selectedIndex", $.selectedGroup[i].index)
                    else $.selectedGroup[i].object.option("selectedItem", $.selectedGroup[i].item)
                onContentReadyDetail($, p)
            }}
            toolbar={{ items: onToolbarDetail($, p) }}
            columnAutoWidth={true}
            keyExpr={p.keyExpr}
            summary={p.summary}
            onEditingStart={(e) => onDetailStart($, e)}
            onInitNewRow={(e) => onDetailStart($, e)}
        >
            <Editing
                allowAdding={p.allowAdding ?? true}
                allowDeleting={p.allowDeleting ?? true}
                allowUpdating={p.allowUpdating ?? true}
                useIcons={true}
                confirmDelete={false}
                mode={p.mode}
                form={p.form}
                popup={{
                    title: $.info.name + " - " + ($.old.key ?? "NEW"),
                    showTitle: true,
                    dragEnabled: false,
                    container: "#pg-" + $.flag,
                    position: { my: "center", at: "center", of: "#pg-" + $.flag },
                    onContentReady: (e) => onContentReadyPopupDetail($),
                    wrapperAttr: { id: "pop-detail-" + $.flag },
                    onHidden: (e) => onContentReadyPopup($),
                    toolbarItems: [
                        {
                            widget: "dxButton",
                            location: "before",
                            options: {
                                icon: "save",
                                text: $.__.save,
                                onClick: () => $.details[name].grid.saveEditData(),
                                elementAttr: { class: "btn-save-detail" }
                            }
                        }
                    ]
                }}
            />
            <Template name="group" render={(e, index) => renderGroup($, e, index)} />
        </DataGrid>
    )
}

export const renderGroup = ($, p, index, x) => {
    if ((p.value === undefined || p.value === null || p.value === "") && p.values.length > 1)
        setTimeout((o) => {
            if (p.cellElement !== undefined) window.$(p.cellElement).parents("tr").css("display", "none")
        })
    else return p.value
}
export const renderMasterDetail = ($, p, selection, index, e, x, i, selected) => {
    e = $.fn.config($.flag)
    if (e.masterDetail === undefined) return <></>
    if (e === undefined || e === null) return <>301 - {$.__.missing}</>
    x = { output: null, collection: e.masterDetail.data }
    if (x.collection.length <= 0) return <>302 - {$.__.missing}</>
    else
        for (i in x.collection) {
            x.data = x.collection[i]
            break
        }
    if (x.data === undefined) return <>303 - {$.__.missing}</>
    if (x.data === null) x.data = {}
    x.settingColumn = (e.open ?? e.details)[i]
    // x.btn = x.settingColumn.filter((expr) => (expr.type !== "buttons" ? false : Array.isArray(expr.buttons)))
    // if (x.button.length <= 0)
    //     x.settingColumn.push({
    //         type: "buttons",
    //         button: []
    //     })
    return (
        <div className="dx-theme-background-color p-2 border-radius-2">
            <div className="dx-theme-accent-as-background-color p-2 border-radius-2 d-inline-block">{$.__.title.detail}</div>
            <DataGrid
                onInitialized={(o) => {
                    if (x.data[p.key] === undefined) {
                        PostData({
                            url: $.fn.url("detail_only"),
                            data: { id: p.key, name: i },
                            showLoading: true,
                            success: (res) => {
                                o.component.option("dataSource", res.data)
                                if (e.masterDetail.data[i] === null) e.masterDetail.data[i] = {}
                                e.masterDetail.data[i][p.key] = { grid: o.component, data: res.data }
                                $.fn.config($.flag, e)
                                onSelectionChanged($, res.key, e)
                            },
                            warning: (res) => alert("<div class=''>" + res.message + "<hr />" + p.key + "</div>", null),
                            error: (res) => alert("<div class='text-danger'>" + res.message + "<hr />" + p.key + "</div>", null)
                        })
                    }
                }}
                onContentReady={(o) => {
                    o.old = o.component.option("dataSource")
                    if (x.data[p.key] !== undefined && (o.old === undefined || o.old === null)) {
                        e.masterDetail.data[i][p.key].grid = o.component
                        o.component.option("dataSource", x.data[p.key].data)
                        onSelectionChanged($, p.key, e)
                    }
                }}
                columns={settingColumn($, x.settingColumn)}
                columnAutoWidth={true}
                showBorders={false}
                showColumnLines={false}
            >
                <Selection mode={selection.mode} />
                <Template name="group" render={(e, index) => renderGroup($, e, index)} />
            </DataGrid>
        </div>
    )
}
