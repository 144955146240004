import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.css"
import "./style.css"
import Content from "./content"
import { changeKey, PostData } from "./Helper"
class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = { ...props }
        if (window.token === undefined || window.token === null || window.token === "") window.token = localStorage.getItem("_key") ?? ""
        this.resetTokenName()
        if (window.token !== "") {
            window.tokenInfo = window.token + ".info"
            window.tokenTheme = window.token + ".theme"
            window.tokenUrl = window.token + ".url"
            window.tokenFront = window.token + ".front"
            window.tokenUnique = window.token + ".unique"
            window.tokenList = window.token + ".list"
            window.tokenClient = window.token + ".client"
            window.url = localStorage.getItem(window.tokenUrl) ?? "" //"api/public/api"
            this.state.info = ""
            if (this.state.info !== "") this.state.info = JSON.parse(this.state.info)
            changeKey(this.state.info)

            this.state.token = localStorage.getItem(window.token)
            if (this.state.token === undefined || this.state.token === null) this.state.token = ""
            this.fn = {
                logout: (e) => {
                    localStorage.clear()
                    window.location.reload()
                }
            }
            this.state.token = localStorage.getItem(window.token)
            this.state.front = localStorage.getItem(window.tokenFront) ?? ""
            if (this.state.info.menu === undefined || this.state.info.menu === null) this.state.info = ""
        }
        localStorage.removeItem(window.tokenClient)
        window.url = process.env.REACT_APP_API_URL
        if (window.url.substring(window.url.length - 1) !== "/") window.url += "/"
        this.state.message = ""
        this.state.code = 200
        this.state.isLoading = window.token !== "" && this.state.token !== "" && this.state.token !== undefined && this.state.token !== null
    }
    resetTokenName = () => {
        this.name = process.env.REACT_APP_PROJECT_NAME
        if (this.name === undefined || this.name === null || this.name === "null") this.name = ""
        if (this.name !== "") {
            if (window.token === "null" || window.token === "" || window.token === undefined || window.token === null || window.resetToken) {
                localStorage.setItem("_key", (window.token = this.name))
                localStorage.setItem(window.token + ".url", process.env.REACT_APP_API_URL)
            }
            window.tokenName = window.token + ".name"
            localStorage.setItem(window.tokenName, this.name)
        } else {
            window.tokenName = ""
        }
    }
    // http://localhost:3000/?v=10856%7CspMbdd5UmwcMQysYXEaAlmaSuavwYnTUMmRGE4hO&n=BDO.BDO&u=http://localhost/office/bdo/public/api/&f=http://localhost/office/front
    componentDidMount(x) {
        if (this.state.isLoading)
            return PostData({
                url: "user/info",
                showLoading: false,
                data: { sleep: 0 },
                success: (e) => this.start(e),
                warning: (e) => this.setState({ message: e.message, info: "", isLoading: false, code: e.code }),
                error: (e) => this.setState({ message: e.message, info: "", isLoading: false, code: e.code })
            })
    }
    start = (e) => {
        e.data.info = e.data
        changeKey(e.data.info)
        e.data.isLoading = false
        e.data.code = 200
        localStorage.setItem(window.tokenClient, e.data.client)
        this.setState(e.data)
    }
    render() {
        if (this.state.isLoading)
            return (
                <div className="d-flex align-items-center height-100 text-left justify-content-center fw-bold">
                    <span className="fa fa-circle-o-notch fa-spin mr-1"></span>Please wait...
                </div>
            )
        if ((this.state.token ?? "") === "" || [401, 402, 403].includes(this.state.code)) return <Login parent={this} start={this.start} />
        if ((this.state.token ?? "") === "" && this.state.front !== "") {
            window.location.href = this.state.front
            return (
                <div className="d-flex align-items-center w-100 h-100 justify-content-center font-lg">
                    <span className="border-bottom border-1 border-dark">Good Bye.. See you later</span>
                </div>
            )
        }
        return (
            <div className="App w-100 h-100">
                {this.state.token !== "" && this.state.info !== "" ? (
                    <Content logout={this.fn.logout}></Content>
                ) : (
                    <Back
                        message={this.state.info === "" ? this.state.message : "Access Forbidden"}
                        url={this.state.front !== "" && !this.state.isLoading ? this.state.front : ""}
                    />
                )}
            </div>
        )
    }
}
export default App
export class LabelInput extends React.Component {
    render() {
        return (
            <>
                <label htmlFor={this.props.id}>{this.props.label}</label>
                <input
                    type={this.props.type}
                    name={this.props.name}
                    className="form-control"
                    id={this.props.id}
                    required={true}
                    autoFocus={this.props.autoFocus}
                />
            </>
        )
    }
}
export class Back extends React.Component {
    render() {
        return (
            <div className="d-flex align-items-center w-100 h-100 justify-content-center font-lg">
                <div className="position-relative text-center">
                    <span className="border-bottom border-1 border-dark pb-4">{this.props.message}</span>
                    <br />
                    <br />
                    {this.props.url !== "" ? (
                        <a href={this.props.url} className="mt-2" style={{ textDecoration: "none" }}>
                            Back
                        </a>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        )
    }
}

export class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = { ...props }
        this.state.isLoading = true
    }
    componentDidMount($, parent) {
        parent = this.state.parent
        $ = parent.state
        PostData({
            url: "list",
            data: { sleep: 0 },
            showLoading: false,
            success: (e) => this.setList(e),
            warning: (e) => this.setState({ message: e.message, info: "", isLoading: false, code: e.code }),
            error: (e) => this.setState({ message: e.message, info: "", isLoading: false, code: e.code })
        })
    }
    setList = (e) => {
        localStorage.setItem(window.tokenList, JSON.stringify(e.data))
        localStorage.setItem(window.tokenClient, e.client)
        if (e.list === undefined || e.list === null) {
            e.list = [...e.data]
            delete e.data
        }
        e.isLoading = false
        this.setState(e)
        window.$("#logo").css({
            height: "125px",
            "background-image": "url('logo/" + e.client + ".png')"
        })
    }
    formSubmit = (e, $, parent, start, fd) => {
        e.preventDefault()
        window.$("#btn-login").prop("disabled", true)
        fd = new FormData(e.target)
        fd.append("is_reset_password", $.isResetPassword ? 1 : 0)
        fd.append("is_send_email", $.isSendEmail ? 1 : 0)
        window
            .$("#message")
            .html('<span class="fa fa-circle-o-notch fa-spin mr-1"></span>Please wait...')
            .removeClass("text-warning")
            .removeClass("text-danger")
            .removeClass("text-success")
        PostData({
            url: "user/login",
            data: fd,
            success: (res) => (res.isSendEmail ? parent.setState({ isSendEmail: true }) : start(res)),
            warning: (e) => {
                window.$("#btn-login").prop("disabled", false)
                window
                    .$("#message")
                    .html('<span class="fa fa-warning mr-1"></span>' + e.message)
                    .addClass("text-warning")
            },
            error: (e) => {
                window.$("#btn-login").prop("disabled", false)
                window
                    .$("#message")
                    .html('<span class="fa fa-times-circle mr-1"></span>' + e.message)
                    .addClass("text-danger")
            },
            showLoading: false
        })
    }
    render($, parent, list, start) {
        parent = this.state.parent
        $ = parent.state
        list = this.state.list
        start = this.state.start
        if (!Array.isArray(list)) list = []
        return (
            <div className="d-flex align-items-center height-100 text-left justify-content-center">
                <form
                    className="mw-90 bg-light-7 p-4 border shadow-sm"
                    style={{ width: "400px" }}
                    onSubmit={(e) => this.formSubmit(e, $, parent, start)}
                >
                    <div className="bg-contain logo w-100" style={{ height: "auto", backgroundImage: "none" }} id="logo"></div>
                    {list.length <= 0 ? (
                        <></>
                    ) : (
                        <select name="unique" className="form-control my-1" id="select-login-list">
                            {list.map((e) => (
                                <option value={e.id}>{e.name}</option>
                            ))}
                        </select>
                    )}
                    <LabelInput id="text-login-username" label="Email / Username" type="text" name="username" autoFocus={true} />
                    {$.isResetPassword && $.isSendEmail ? <LabelInput id="text-login-otp" label="OTP" type="text" name="otp" /> : <></>}
                    {!$.isResetPassword || $.isSendEmail ? (
                        <LabelInput id="text-login-password" label="Password" type="password" name="password" />
                    ) : (
                        <></>
                    )}
                    {$.isResetPassword && $.isSendEmail ? (
                        <LabelInput id="text-login-confirm-password" label="Confirm Password" type="password" name="confirm_password" />
                    ) : (
                        <></>
                    )}
                    {this.state.isLoading ? (
                        <div className="fw-bold">
                            <span className="fa fa-circle-o-notch fa-spin mr-1"></span>Please wait...
                        </div>
                    ) : (
                        <div id="message" className="fw-bold mt-2"></div>
                    )}
                    <button type={"submit"} className="btn btn-dark mt-2" disabled={list.length <= 0} id="btn-login">
                        {$.isSendEmail ? "Change Password" : $.isResetPassword ? "Request OTP" : "Login"}
                    </button>
                    {!this.state.hasResetPassword ? (
                        <></>
                    ) : (
                        <div
                            className="btn btn-light ml-2 mt-2"
                            onClick={(e) => parent.setState({ isResetPassword: !$.isResetPassword, isSendEmail: false })}
                        >
                            {$.isResetPassword ? "Login" : "Forget Password?"}
                        </div>
                    )}
                </form>
            </div>
        )
    }
}
