import React from "react"
import { PostData, onYmd, onHms, onDmy } from "../../../Helper"
import { DataGrid, Scheduler, TreeList } from "devextreme-react"
import { Column, Item, Summary, Toolbar, TotalItem } from "devextreme-react/data-grid"
import {
    Column as ColumnTree,
    Item as ItemTree,
    Summary as SummaryTree,
    Toolbar as ToolbarTree,
    TotalItem as TotalItemTree
} from "devextreme-react/tree-list"
import { Editing } from "devextreme-react/scheduler"

class ProfitAndLossReport extends React.Component {
    constructor(props) {
        super(props)
        this.fn = props.$.fn
        this.state = { ...props }
        this.state.start = null
        this.state.end = null
        this.scheduler = null
        this.isSaveOk = false
        this.isDelete = false
        this.columns = ["id", "name", "date_start", "date_end", "time_start", "time_end", "description", "is_form"]
    }
    componentDidMount() {
        if (!this.state.ready) this.onRefresh(this.parent)
    }
    onRefresh = (p) => {
        if (p === undefined) p = {}
        if (p.isShowLoading === undefined || p.isShowLoading === null) p.isShowLoading = false
        if (p.current === undefined) p.current = null
        PostData({
            showLoading: p.isShowLoading,
            url: this.fn.url("index"),
            data: { current: p.current },
            success: (e) => this.setState(e),
            error: (e) => this.setState({ message: e.message, data: undefined }),
            warning: (e) => this.setState({ message: e.message, data: undefined })
        })
    }
    onSaving = (mode, e, data, k, form, ori) => {
        ori = mode === "edit" ? e.newData : e.appointmentData
        if (this.isSaveOk) {
            this.isSaveOk = false
        } else {
            e.cancel = true
            data = { ...ori }
            if (mode !== "delete") {
                form = e.component._appointmentForm.form
                if (isNaN(data.is_form)) data.is_form = 0
                if (+data.is_form === 0) data.date_end = new Date(onYmd(data.display_date_end)).addDays(-1)
                for (k in data) if (!this.columns.includes(k)) delete data[k]
                if (data.date_start !== undefined && data.date_start !== null) data.date_start = onYmd(data.date_start)
                if (data.date_end !== undefined && data.date_end !== null) data.date_end = onYmd(data.date_end)
                if (data.time_start !== undefined && data.time_start !== null) data.time_start = onHms(data.time_start)
                if (data.time_end !== undefined && data.time_end !== null) data.time_end = onHms(data.time_end)
            }
            if (form === undefined) form = null
            data._mode = mode
            PostData({
                showLoading: true,
                url: this.fn.url("set"),
                data: data,
                success: (rs) => {
                    this.isSaveOk = true
                    if (rs.mode === "delete") return this.scheduler.deleteAppointment(ori)
                    if (rs.id !== null && rs.id !== undefined) data.id = rs.id
                    if (rs.display_date_end !== null && rs.display_date_end !== undefined) data.display_date_end = rs.display_date_end
                    if (rs.is_form === 1) {
                        if (form !== null)
                            if (typeof form.updateData === "function") {
                                form.updateData("is_form", 0)
                                if (rs.id !== null && rs.id !== undefined) form.updateData("id", rs.id)
                                if (rs.display_date_end !== null && rs.display_date_end !== undefined)
                                    form.updateData("display_date_end", rs.display_date_end)
                            }
                        this.scheduler.hideAppointmentPopup(true)
                    } else {
                        this.isSaveOk = false
                        return this.onRefresh({ isShowLoading: true })
                    }
                }
            })
        }
    }
    render() {
        if (this.state.message !== undefined && this.state.message !== null && this.state.message !== "")
            return (
                <>
                    <div className="alt-font-bold">{this.state.message}</div>
                    <button className="btn btn-light btn-sm mt-1" onClick={() => this.onRefresh()}>
                        <span className="fa fa-exchange" /> Retry
                    </button>
                </>
            )
        if (this.state.data)
            return (
                <div className="row w-100 h-100">
                    <div className="col-md-8 h-100">
                        <Scheduler
                            onCurrentDateChange={(e) => this.onRefresh({ current: onYmd(e) })}
                            dataSource={this.state.data}
                            startDateExpr="date_start"
                            endDateExpr="display_date_end"
                            textExpr="name"
                            descriptionExpr="description"
                            defaultCurrentDate={this.state.current}
                            defaultCurrentView="month"
                            onAppointmentAdding={(e) => this.onSaving("new", e)}
                            onAppointmentDeleting={(e) => this.onSaving("delete", e)}
                            onAppointmentUpdating={(e) => this.onSaving("edit", e)}
                            onContentReady={(e, i, x, obj) => {
                                this.scheduler = e.component
                                obj = window.$("#calendar-detail").html("")
                                for (i in this.state.data) {
                                    x = { ...this.state.data[i] }
                                    x.start = onDmy(x.date_start)
                                    x.end = onDmy(x.date_end)
                                    x.date = x.start
                                    if (x.start !== x.end) x.date = x.start + " - " + x.end
                                    obj.append(
                                        window
                                            .$('<div class="border-top border-bottom p-1">')
                                            .append(window.$('<div class="fw-bold">').append("[" + x.name + "] " + x.date))
                                            .append(
                                                window
                                                    .$('<div class="fst-italic">')
                                                    .append(
                                                        window
                                                            .$('<span class="border-bottom border-top">')
                                                            .append("* " + onHms(x.time_start) + " - " + onHms(x.time_end))
                                                    )
                                            )
                                            .append(x.description)
                                    )
                                }
                            }}
                            width={"100%"}
                            height={"100%"}
                            views={["month"]}
                            dataCellComponent={(e, x) => {
                                x = { text: e.data.text }
                                x.day = e.data.startDate.getDay()
                                return <div className={x.day === 0 ? "text-danger" : x.day === 6 ? "text-primary" : ""}>{x.text}</div>
                            }}
                            appointmentTooltipComponent={(e, data, x) => {
                                data = e.data.appointmentData
                                x = { start: onDmy(data.date_start), end: onDmy(data.date_end) }
                                return (
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="d-flex w-100 h-100" style={{ justifyContent: "right" }}>
                                                <button
                                                    className="btn btn-sm"
                                                    onClick={() => {
                                                        this.isDelete = true
                                                        this.scheduler.deleteAppointment(data)
                                                    }}
                                                >
                                                    <span className="fa fa-trash font-lg"></span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-9 text-left">
                                            <div className="font-lg fw-bold">{x.start === x.end ? x.start : x.start + "-" + x.end}</div>
                                            <div className="fst-italic">
                                                <span className="border-bottom border-top">
                                                    * {onHms(data.time_start)} - {onHms(data.time_end)}
                                                </span>
                                            </div>
                                            <div>{data.description}</div>
                                        </div>
                                    </div>
                                )
                            }}
                            onAppointmentFormOpening={(e, data) => {
                                if (this.isDelete) {
                                    this.isDelete = false
                                    e.form.option("items", [])
                                    return
                                }
                                data = { ...e.appointmentData }
                                if (data.id === undefined || data.id === null) {
                                    if (data.date_end === undefined || data.date_end === null) data.date_end = data.display_date_end
                                    if (typeof data.date_end === "string") data.date_end = new Date(data.date_end)
                                    if (typeof data.date_start === "string") data.date_start = new Date(data.date_start)
                                    if (data.date_end !== undefined && data.date_end !== null) data.date_end = data.date_end.addDays(-1)
                                }
                                e.form.updateData("date_start", onYmd(data.date_start))
                                e.form.updateData("date_end", onYmd(data.date_end))
                                e.form.updateData("is_form", 1)
                                e.form.option("items", [
                                    {
                                        dataField: "id",
                                        editorType: "dxTextBox",
                                        colSpan: 2,
                                        editorOptions: { visible: false },
                                        label: { visible: false }
                                    },
                                    {
                                        dataField: "name",
                                        editorType: "dxTextBox",
                                        colSpan: 2
                                    },
                                    {
                                        dataField: "date_start",
                                        editorType: "dxDateBox",
                                        editorOptions: {
                                            width: "100%",
                                            displayFormat: "dd MMM yyyy"
                                        }
                                    },
                                    {
                                        dataField: "date_end",
                                        label: { text: "Date End" },
                                        editorType: "dxDateBox",
                                        editorOptions: {
                                            width: "100%",
                                            displayFormat: "dd MMM yyyy"
                                        }
                                    },
                                    {
                                        dataField: "time_start",
                                        editorType: "dxDateBox",
                                        editorOptions: {
                                            width: "100%",
                                            type: "datetime",
                                            displayFormat: "shortTime",
                                            calendarOptions: { visible: false }
                                        }
                                    },
                                    {
                                        dataField: "time_end",
                                        editorType: "dxDateBox",
                                        editorOptions: {
                                            width: "100%",
                                            type: "datetime",
                                            displayFormat: "shortTime",
                                            calendarOptions: { visible: false }
                                        }
                                    },
                                    {
                                        dataField: "description",
                                        editorType: "dxTextArea",
                                        colSpan: 2
                                    }
                                ])
                            }}
                        >
                            <Editing
                                allowAdding={this.state.access.new}
                                allowDeleting={this.state.access.delete}
                                allowUpdating={this.state.access.edit}
                                allowDragging={this.state.access.edit}
                                allowResizing={this.state.access.edit}
                            />
                        </Scheduler>
                    </div>
                    <div className="col-md-4 h-100" style={{ overflowX: "hidden", overflowY: "auto" }} id="calendar-detail"></div>
                </div>
            )
        return (
            <div className="alt-font-bold">
                <span className="fa fa-circle-o-notch fa-spin"></span> Please wait...
            </div>
        )
    }
}
export default ProfitAndLossReport
