import { Chart, DataGrid, PieChart } from "devextreme-react"
import { Column, Item, Summary, Toolbar, TotalItem } from "devextreme-react/data-grid"
import { Connector, Label, Legend, Series } from "devextreme-react/chart"
import React from "react"

export class ProfitAndLoss extends React.Component {
    constructor(props) {
        super(props)
        this.$ = props.parent
        this.fn = props.fn
        this.state = { ...props }
    }
    componentDidMount() {}
    onRefresh = () => {}
    render($, x) {
        // return <></>
        $ = this.$
        x = this.state.data.profit_and_loss
        return (
            <div className="row mx-0 mb-3">
                {$.info.access.includes("profit_and_loss") ? (
                    <div className="col-md-12">
                        <Chart dataSource={x.data} title={x.title} width={"100%"} height={"100%"} elementAttr={{'style':'overflow-x: hidden'}}>
                            <Series argumentField="display" valueField="value" type="bar">
                                <Label visible={true} customizeText={(e) => e.argumentText + "\r\n" + e.value.toLocaleString("en-US")}>
                                    <Connector visible={true} width={1} />
                                </Label>
                            </Series>
                            <Legend visible={false} />
                        </Chart>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        )
    }
}
