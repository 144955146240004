import { DataGrid } from "devextreme-react"
import { Column, Item, Summary, Toolbar, TotalItem } from "devextreme-react/data-grid"
import React from "react"

export class Debt extends React.Component {
    constructor(props) {
        super(props)
        this.$ = props.parent
        this.fn = props.fn
        this.state = { ...props }
    }
    componentDidMount() {}
    onRefresh = () => {}
    render($, title) {
        $ = this.$
        title = this.state.title
        return (
            <div className="row m-0">
                {$.info.access.includes("customer_debt") && (this.state.data.customer_debt ?? []).length > 0 ? (
                    <div className="col-md-12">
                        <DataGrid
                            dataSource={this.state.data.customer_debt}
                            paging={{ pageSize: 10 }}
                            searchPanel={{ visible: true }}
                            export={{ enabled: true }}
                            columnAutoWidth={true}
                        >
                            <Toolbar>
                                <Item location={"before"} name={"exportButton"} />
                                <Item location={"before"}>{$.__.customer_debt}</Item>
                                <Item location={"after"} name={"searchPanel"} />
                            </Toolbar>
                            {title.map((e) => (
                                <Column
                                    dataField={e.dataField}
                                    caption={e.caption}
                                    dataType={
                                        e.dataField === "due_date"
                                            ? "date"
                                            : ["total", "paid", "discount", "payment_left"].includes(e.dataField)
                                            ? "number"
                                            : undefined
                                    }
                                    format={
                                        e.dataField === "due_date"
                                            ? "dd MMM yyyy"
                                            : ["total", "paid", "discount", "payment_left"].includes(e.dataField)
                                            ? "#,##0.##;-#,##0.##"
                                            : undefined
                                    }
                                ></Column>
                            ))}
                            <Summary>
                                <TotalItem
                                    column="total"
                                    summaryType="sum"
                                    valueFormat="#,##0.##;-#,##0.##"
                                    customizeText={(e) => (isNaN(e.value) ? 0 : (+e.value).toLocaleString("en-US"))}
                                />
                                <TotalItem
                                    column="paid"
                                    summaryType="sum"
                                    valueFormat="#,##0.##;-#,##0.##"
                                    customizeText={(e) => (isNaN(e.value) ? 0 : (+e.value).toLocaleString("en-US"))}
                                />
                                <TotalItem
                                    column="discount"
                                    summaryType="sum"
                                    valueFormat="#,##0.##;-#,##0.##"
                                    customizeText={(e) => (isNaN(e.value) ? 0 : (+e.value).toLocaleString("en-US"))}
                                />
                                <TotalItem
                                    column="payment_left"
                                    summaryType="sum"
                                    valueFormat="#,##0.##;-#,##0.##"
                                    customizeText={(e) => (isNaN(e.value) ? 0 : (+e.value).toLocaleString("en-US"))}
                                />
                            </Summary>
                        </DataGrid>
                    </div>
                ) : (
                    <></>
                )}
                {$.info.access.includes("supplier_debt") && (this.state.data.supplier_debt ?? []).length > 0 ? (
                    <div className="col-md-12">
                        <DataGrid
                            dataSource={this.state.data.supplier_debt}
                            paging={{ pageSize: 10 }}
                            searchPanel={{ visible: true }}
                            export={{ enabled: true }}
                            columnAutoWidth={true}
                        >
                            <Toolbar>
                                <Item location={"before"} name={"exportButton"} />
                                <Item location={"before"}>{$.__.supplier_debt}</Item>
                                <Item location={"after"} name={"searchPanel"} />
                            </Toolbar>
                            {title.map((e) => (
                                <Column
                                    dataField={e.dataField}
                                    caption={e.caption}
                                    dataType={
                                        e.dataField === "due_date"
                                            ? "date"
                                            : ["total", "paid", "discount", "payment_left"].includes(e.dataField)
                                            ? "number"
                                            : undefined
                                    }
                                    format={
                                        e.dataField === "due_date"
                                            ? "dd MMM yyyy"
                                            : ["total", "paid", "discount", "payment_left"].includes(e.dataField)
                                            ? "#,##0.##;-#,##0.##"
                                            : undefined
                                    }
                                ></Column>
                            ))}
                            <Summary>
                                <TotalItem
                                    column="total"
                                    summaryType="sum"
                                    valueFormat="#,##0.##;-#,##0.##"
                                    customizeText={(e) => (isNaN(e.value) ? 0 : (+e.value).toLocaleString("en-US"))}
                                />
                                <TotalItem
                                    column="paid"
                                    summaryType="sum"
                                    valueFormat="#,##0.##;-#,##0.##"
                                    customizeText={(e) => (isNaN(e.value) ? 0 : (+e.value).toLocaleString("en-US"))}
                                />
                                <TotalItem
                                    column="discount"
                                    summaryType="sum"
                                    valueFormat="#,##0.##;-#,##0.##"
                                    customizeText={(e) => (isNaN(e.value) ? 0 : (+e.value).toLocaleString("en-US"))}
                                />
                                <TotalItem
                                    column="payment_left"
                                    summaryType="sum"
                                    valueFormat="#,##0.##;-#,##0.##"
                                    customizeText={(e) => (isNaN(e.value) ? 0 : (+e.value).toLocaleString("en-US"))}
                                />
                            </Summary>
                        </DataGrid>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        )
    }
}
