import React from "react"
import { PostData } from "../../../Helper"
import { DataGrid, TreeList } from "devextreme-react"
import { Column, Item, Summary, Toolbar, TotalItem } from "devextreme-react/data-grid"
import {
    Column as ColumnTree,
    Item as ItemTree,
    Summary as SummaryTree,
    Toolbar as ToolbarTree,
    TotalItem as TotalItemTree
} from "devextreme-react/tree-list"

class ProfitAndLossReport extends React.Component {
    constructor(props) {
        super(props)
        this.fn = props.$.fn
        this.state = { ...props }
        this.dateStart = null
        this.dateEnd = null
    }
    componentDidMount() {
        if (!this.state.ready) this.onRefresh(this.parent)
    }
    onRefresh = () => {
        PostData({
            showLoading: true,
            url: this.fn.url("index"),
            data: { dateStart: this.dateStart, dateEnd: this.dateEnd },
            success: (e) => this.setState(e),
            error: (e) => this.setState({ message: e.message, data: undefined }),
            warning: (e) => this.setState({ message: e.message, data: undefined })
        })
    }
    render($, output, data, title, dateStart, dateEnd, cols, colTrees, colSum) {
        $ = this.state.$
        this.dateStart = this.state.dateStart
        this.dateEnd = this.state.dateEnd
        output = this.state.output
        data = this.state.data
        dateStart = this.state.dateStart
        dateEnd = this.state.dateEnd
        title = this.state.title
        cols = this.state.cols
        colTrees = this.state.colTrees
        colSum = this.state.colSum
        if (data !== undefined && data !== null)
            return (
                <TreeList
                    dataSource={data}
                    keyExpr={"id"}
                    parentIdExpr={"parent_id"}
                    autoExpandAll={true}
                    width={"100%"}
                    height={"100%"}
                    columnAutoWidth={true}
                    onRowPrepared={(e) => {
                        if (e.rowType === "data")
                            if (e.data.name === null || e.data.name === undefined) {
                                e.rowElement.style.fontWeight = "bold"
                                e.rowElement.style.backgroundColor = +(e.data.is_last ?? "0") === 0 ? "#E8E8E8" : "#c7c7c7"
                            }
                    }}
                >
                    {colTrees.map((e) => (
                        <ColumnTree dataField={e.dataField} dataType={e.dataType} format={e.format} groupIndex={e.group} caption={e.caption}></ColumnTree>
                    ))}
                    <ToolbarTree>
                        <ItemTree location={"before"} name={"exportButton"} />
                        <ItemTree
                            location={"before"}
                            widget={"dxDateBox"}
                            options={{
                                value: this.dateStart,
                                width: 125,
                                displayFormat: "MMM yyyy",
                                onValueChanged: (e) => (this.dateStart = e.value),
                                calendarOptions: {
                                    maxZoomLevel: "year",
                                    minZoomLevel: "century"
                                }
                            }}
                        />
                        <ItemTree
                            location={"before"}
                            widget={"dxDateBox"}
                            options={{
                                value: this.dateEnd,
                                width: 125,
                                displayFormat: "MMM yyyy",
                                onValueChanged: (e) => (this.dateEnd = e.value),
                                calendarOptions: {
                                    maxZoomLevel: "year",
                                    minZoomLevel: "century"
                                }
                            }}
                        />
                        <ItemTree location={"before"} widget={"dxButton"} options={{ icon: "filter", onClick: () => this.onRefresh() }} />
                        <ItemTree location={"after"} name={"searchPanel"} />
                    </ToolbarTree>
                </TreeList>
            )
        else if (this.state.message)
            return (
                <>
                    <div className="alt-font-bold">{this.state.message}</div>
                    <button className="btn btn-light btn-sm mt-1" onClick={() => this.onRefresh()}>
                        <span className="fa fa-exchange" /> Retry
                    </button>
                </>
            )
        else
            return (
                <div className="alt-font-bold">
                    <span className="fa fa-circle-o-notch fa-spin"></span> Please wait...
                </div>
            )
    }
}
export default ProfitAndLossReport
