import React from "react"
// import ChangePassword from "./content/changePassword"
import MainContent from "./content/mainContent"

import Drawer from "devextreme-react/drawer"
import TreeList, { Column, FilterRow } from "devextreme-react/tree-list"
import TabPanel from "devextreme-react/tab-panel"
import { Template } from "devextreme-react/core/template"
import GoogleLogin from "react-google-login"
import { SpeedDialAction } from "devextreme-react/speed-dial-action"
import config from "devextreme/core/config"
import { PostData } from "./Helper"

class Content extends React.Component {
    constructor(props) {
        super(props)
        this.state = { ...props }
        this.fn = {
            modal: (e) => this.renderModal(e)
        }
        this.token = localStorage.getItem(window.token)
        if (this.token === undefined || this.token === null) this.token = ""
        this.info = JSON.parse(localStorage.getItem(window.tokenInfo))
        if (this.info.menu.filter((e) => e.id === "content.dashboard").length <= 0)
            this.info.menu.unshift({ id: "content.dashboard", parent: "content", name: "Home", icon: "fa fa-home" })
        if (this.info.menu.filter((e) => e.id === "content").length <= 0) this.info.menu.unshift({ id: "content", parent: null, name: "Main" })
        this.settingMenu(this.info.menu)

        this.state.isSm = window.innerWidth <= 670
        this.state.isMd = window.innerWidth <= 767
        this.opened = !this.state.isMd

        this.defaultMenu = this.info.menu.filter((expr) => expr.id === "content.dashboard" || expr.id === "content/dashboard")
        if (this.defaultMenu.length > 0) this.defaultMenu = this.defaultMenu[0]

        this.data = {}
        this.columns = {}
        this.details = {}
        this.config = {}
        this.selectedIndex = { old: 0, new: 0 }
    }
    fileExists = (path) => {
        try {
            return require("../src/pages/" + path)
        } catch (err) {
            return null
        }
    }
    settingMenu = (e, x) => {
        x = { i: 0, temp: {} }
        for (x.i in e)
            if (e[x.i].parent !== undefined && e[x.i].parent !== null) {
                if (e[x.i].id === "") e[x.i].id = "dashboard"
                else e[x.i].id = e[x.i].id.replace(".", "/")
                e[x.i].splitId = e[x.i].id.split("/")
                if (e[x.i].splitId.length <= 1) e[x.i].splitId.push(e[x.i].splitId[0])
                e[x.i].fileName = e[x.i].id

                x.temp[x.i] = { exists: this.fileExists(e[x.i].fileName + ".js") }
                if (x.temp[x.i].exists === null) x.temp[x.i].exists = this.fileExists((e[x.i].fileName = "content/default") + ".js")

                e[x.i].state = {
                    info: {
                        ...e[x.i],
                        dataSource: []
                    }
                }
            }
    }
    componentDidMount(e) {
        window.addEventListener("resize", this.updateWindowDimensions)
        config({
            floatingActionButtonConfig: {
                icon: "rowfield",
                shading: true,
                position: {
                    my: "left bottom",
                    at: "left bottom",
                    offset: "5 -5"
                }
            }
        })
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }
    updateWindowDimensions = (e) => {
        e = {
            isSm: window.innerWidth <= 670,
            isMd: window.innerWidth <= 767
        }
        if (e.isSm !== this.state.isSm) this.setState({ isSm: e.isSm })
        if (e.isMd !== this.state.isMd) this.setState({ isMd: e.isMd })
    }
    onMenuClick = (e, x) => {
        this.onSelected({ x: e.data })
    }
    onSelected = (e) => {
        if (e.x.parent !== null) this.mainTab.option("selectedItem", e.x)
    }
    themeList = () => [
        { id: "carmine", name: "Carmine" },
        { id: "contrast", name: "Contrast" },
        { id: "dark", name: "Dark" },
        { id: "darkmoon", name: "Dark Moon" },
        { id: "darkviolet", name: "Dark Violet" },
        { id: "greenmist", name: "Green Mist" },
        { id: "light", name: "Light" },
        { id: "softblue", name: "Soft Blue" }
    ]
    menuToolBar = (e, x) => {
        x = { url: localStorage.getItem(window.tokenFront) ?? "" }
        if (x.url !== "")
            e.toolbarOptions.items.push({
                location: "before",
                widget: "dxButton",
                options: {
                    padding: 0,
                    icon: "chevrondoubleleft",
                    type: "default",
                    text: "back",
                    onClick: (e) => {
                        window.location.href = x.url
                    }
                }
            })
        e.toolbarOptions.items.push({
            location: "after",
            widget: "dxButton",
            options: {
                icon: "runner",
                type: "danger",
                onClick: () => {
                    PostData({ url: "user/logout", close: this.state.logout })
                }
            }
        })
        if (this.info.language !== undefined)
            e.toolbarOptions.items.push({
                location: "after",
                widget: "dxSelectBox",
                options: {
                    width: 70,
                    items: this.info.languages,
                    value: this.info.language,
                    displayExpr: "name",
                    valueExpr: "id",
                    onValueChanged: (e) => {
                        PostData({ url: "user/language", data: { language: e.value }, showLoading: true, success: (res) => window.location.reload() })
                    }
                }
            })
        this.themeList().map((expr) =>
            e.toolbarOptions.items.push({
                locateInMenu: "always",
                widget: "dxButton",
                options: {
                    text: expr.name,
                    onClick: () => this.updateTheme(expr.id)
                }
            })
        )
    }
    speedIcon = () => {
        return this.drawer === undefined ? (this.opened ? "close" : "menu") : this.drawer.option("opened") ? "close" : "menu"
    }
    renderMenu = (e) => {
        return this.state.ready ? (
            <>
                <Drawer
                    defaultOpened={this.opened}
                    openedStateMode={"shrink"}
                    position={"left"}
                    revealMode={"expand"}
                    component={this.renderNavigation}
                    className="position-fixed top-0"
                    onInitialized={(expr) => (this.drawer = expr.component)}
                >
                    <TabPanel
                        deferRendering={true}
                        dataSource={this.info.menu.filter((e) => {
                            return e.parent !== null && e.parent !== undefined
                        })}
                        itemRender={this.renderItemMain}
                        itemTitleRender={this.renderTitleMain}
                        keyExpr="id"
                        defaultSelectedItem={this.defaultMenu}
                        onInitialized={(e) => (this.mainTab = e.component)}
                        className="hide-title"
                        onSelectedIndexChange={(e) => {
                            this.selectedIndex.new = e
                        }}
                        onSelectedItemChange={(e, x) => {
                            if (this.selectedIndex.new === this.selectedIndex.old) return
                            else this.selectedIndex.old = this.selectedIndex.new
                            x = e.state.info
                            if (typeof x.onRefresh === "function") x.onRefresh({ showLoading: false })
                        }}
                    />
                </Drawer>
                <SpeedDialAction
                    onInitialized={(expr) => {
                        this.btnMenu = expr.component
                        this.btnMenu.option("icon", this.speedIcon())
                    }}
                    index={1}
                    onClick={() => {
                        this.drawer.toggle()
                        this.btnMenu.option("icon", this.speedIcon())
                    }}
                />
            </>
        ) : (
            <></>
        )
    }
    renderTitleMain = (e) => {
        return <span className="pr-4">{e.name}</span>
    }
    renderNavigation = (e) => {
        return (
            <TreeList
                onToolbarPreparing={this.menuToolBar}
                dataSource={this.info.menu}
                parentIdExpr="parent"
                keyExpr="id"
                displayExpr="name"
                selectionMode="single"
                dataStructure="plain"
                selectByClick={true}
                searchEnabled={true}
                searchMode="contains"
                onInitialized={(e) => (this.tree = e.component)}
                elementAttr={{
                    class: "dx-theme-background-color dx-theme-text-color p-1 border-right dx-theme-border-color height-100 pb-5 pt-2"
                }}
                width={300}
                onRowClick={this.onMenuClick}
                filterMode="fullBranch"
                autoExpandAll={true}
                showColumnHeaders={false}
            >
                <FilterRow visible={true} />
                <Column
                    dataField="name"
                    allowSorting={false}
                    cellRender={(e) => {
                        return (
                            <>
                                <span className={e.data.icon}></span>
                                <span className="ml-1">{e.data.name}</span>
                            </>
                        )
                    }}
                />
                <Template name="renderGoogle" render={this.renderGoogle} />
            </TreeList>
        )
    }
    renderGoogle = () => {
        return (
            <GoogleLogin
                clientId={this.info.google}
                buttonText="Login"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogleFailed}
                cookiePolicy={"single_host_origin"}
                className="google-toolbar btn shadow-none border"
            />
        )
    }
    responseGoogle = (e) => {}
    responseGoogleFailed = (e) => {
        this.state.fn.warning("Failed to connect google")
    }
    updateTheme = (e) => {
        PostData({ url: "user/theme", data: { theme: e }, success: (res) => {} })
        localStorage.setItem(window.tokenTheme, e)
        this.linkTheme.href = "css/dx." + e + ".css"
    }
    renderTheme = () => {
        return (
            <link
                rel="stylesheet"
                type="text/css"
                href={"css/dx." + ((this.info.theme ?? "") === "" ? "carmine" : this.info.theme) + ".css"}
                onLoad={(e) => {
                    this.linkTheme = e.target
                    this.setState({ ready: true })
                }}
            />
        )
    }
    renderItemMain = (x) => {
        return (
            <div className="width-100 height-100 position-relative">
                <MainContent
                    state={x.state ?? { info: x, dataSource: [] }}
                    content={{
                        data: (e, data) => {
                            if (data === undefined)
                                return e === null || e === undefined ? this.data : (this.data[e] ?? []).sort((a, b) => a.updated_at > b.updated_at)
                            else return (this.data[e] = data)
                        },
                        column: (e, data) => {
                            if (data === undefined) return e === null || e === undefined ? this.columns : this.columns[e]
                            else return (this.columns[e] = data)
                        },
                        detail: (e, data) => {
                            if (data === undefined) return e === null || e === undefined ? this.details : this.details[e]
                            else return (this.details[e] = data)
                        },
                        config: (e, data) => {
                            if (data === undefined) return e === null || e === undefined ? this.config : this.config[e]
                            else return (this.config[e] = data)
                        }
                    }}
                    logout={this.state.logout}
                />
            </div>
        )
    }
    renderModal = (e) => {
        // return <Loading></Loading>
    }
    render() {
        return this.token === "" ? (
            <div className="d-flex align-items-center w-100 h-100 justify-content-center font-lg">
                <span className="border-bottom border-1 border-dark">Content Access Forbidden</span>
            </div>
        ) : (
            <div className="Content">
                {/* {JSON.stringify(this.token)} */}
                <this.renderTheme />
                <this.renderMenu />
            </div>
        )
    }
}
export default Content
