import { confirm } from "devextreme/ui/dialog"
import { Workbook } from "exceljs"
import { saveAs } from "file-saver"
import { exportDataGrid } from "devextreme/excel_exporter"
import { PostData } from "../../../Helper"
import { settingColumn, settingEditing, settingSelection } from "./setting"
import { custom } from "devextreme/ui/dialog"
import { bdoToolbarClick } from "../custom/bdoFollowUp"

export const onFindTab = ($, e, x, j) => {
    x = {
        tabs: (e.items ?? []).filter((expr) => expr.itemType === "tabbed" && (Array.isArray(expr.tabs) ? expr.tabs.length > 1 : false))
    }
    if (x.tabs.length > 0) for (j in x.tabs) onInitializedTab($, x.tabs[j])
}
export const onInitializedTab = ($, e, x, j) => {
    x = { ctr: 0 }
    if (e.tabPanelOptions === undefined) e.tabPanelOptions = { deferRendering: false }
    if (e.name === undefined) e.name = "X" + (x.ctr = +x.ctr + 1)
    if (e.tabPanelOptions.onInitialized === undefined)
        e.tabPanelOptions.onInitialized = (a) => {
            if ($.selectedGroup[e.name] === undefined) $.selectedGroup[e.name] = { index: 0, item: null }
            else $.selectedGroup[e.name].object = a.component
        }
    if (e.tabPanelOptions.onSelectionChanged === undefined)
        e.tabPanelOptions.onSelectionChanged = (a) => {
            if (a.addedItems.length >= 1) {
                for (j in e.tabs) if (e.tabs[j].name === a.addedItems[0].name) break
                $.selectedGroup[e.name].index = j
            }
        }
}
export const onStart = ($) => {
    $.setState({ error: undefined, warning: undefined })
    if (($.state.config === null || $.state.config === undefined) && !$.waiting) {
        $.waiting = true
        PostData({
            url: $.fn.url("config"),
            showLoading: true,
            success: (e, i, help) => {
                e.columns = settingColumn($, e.columns, e)
                $.fn.column($.flag, e.columns)
                if (e.details !== undefined)
                    for (i in e.details) {
                        help = {
                            btn: e.details[i].filter((expr) => (expr.type !== "buttons" ? false : Array.isArray(expr.buttons)))
                        }
                        if (help.btn.length <= 0)
                            e.details[i].push({
                                type: "buttons",
                                buttons: ["edit", "delete"]
                            })
                        e.details[i] = settingColumn($, e.details[i], e)
                        $.fn.detail($.flag + "_" + i, e.details[i])
                    }
                if (e.forms !== undefined) if (Array.isArray(e.forms.items)) for (i in e.forms.items) onFindTab($, e.forms.items[i])
                $.fn.config($.flag, e)
                if (e.editing !== undefined && e.editing !== null) settingEditing($, e.editing, e.form)
                $.waiting = false
                // $.updateState({ columns: e.columns, config: e })
                $.setState({ columns: e.columns, config: e })
                if (e.maxDate === undefined && e.minDate === undefined && e.filter === undefined) e.extra.push($.flag)
                for (i in e.extra) $.collection[e.extra[i]] = null
            },
            error: (e) => {
                if (e.response === undefined) e.response = { data: "Sorry, there's something when wrong / connection timeout" }
                $.waiting = false
                $.setState({ error: e.response.data.message })
            },
            warning: (e) => {
                $.waiting = false
                $.setState({ warning: e.message })
            }
        })
    }
}
export const onToolbarPreparing = ($, e, x, i, tmp) => {
    if ($.access.includes("new"))
        e.toolbarOptions.items.push({
            cssClass: "btn-new-row",
            location: "after",
            widget: "dxButton",
            options: {
                icon: "plus",
                onClick: () => $.grid.addRow()
            }
        })
    e.toolbarOptions.items.push({
        location: "before",
        widget: "dxButton",
        options: {
            icon: "refresh",
            onClick: () => onRefresh($)
        }
    })
    if ($.access.includes("import"))
        e.toolbarOptions.items.push({
            location: "before",
            template: "importExcel"
        })
    x = $.fn.config($.flag)
    if (x.maxDate !== undefined && x.minDate !== undefined) {
        if ($.minDate === undefined) $.minDate = x.minDate
        if ($.maxDate === undefined) $.maxDate = x.maxDate
        e.toolbarOptions.items.push({
            location: "before",
            widget: "dxDateBox",
            options: {
                value: $.minDate,
                width: 125,
                displayFormat: "dd MMM yyyy",
                onValueChanged: (a) => ($.minDate = a.value)
            }
        })
        e.toolbarOptions.items.push({
            location: "before",
            widget: "dxDateBox",
            options: {
                value: $.maxDate,
                width: 125,
                displayFormat: "dd MMM yyyy",
                onValueChanged: (a) => ($.maxDate = a.value)
            }
        })
    }
    if (x.filter !== undefined)
        for (i in x.filter) {
            e.toolbarOptions.items.push({
                location: "before",
                template: "dropDown",
                data: x.filter[i],
                target: i
            })
            // if ($.filter[i] === undefined) $.filter[i] = ""
            // tmp = x.filter[i]
            // if (tmp.table !== undefined) {
            //     e.toolbarOptions.items.push({
            //         location: "before",
            //         widget: "dxDropDownBox",
            //         options: {
            //             value: $.filter[i],
            //             width: 125,
            //             valueExpr: tmp.valueExpr,
            //             displayExpr: tmp.displayExpr,
            //             dataSource: $.fn.column(tmp.table),
            //             contentTemplate: (e) => onDropDownTemplate($, e, tmp)
            //         }
            //     })
            // }
        }
    if ((x.maxDate !== undefined && x.minDate !== undefined) || x.filter !== undefined)
        e.toolbarOptions.items.push({
            location: "before",
            widget: "dxButton",
            options: { icon: "filter", onClick: () => onFilterDate($) }
        })
    if (x.toolbar !== undefined && x.toolbar !== undefined)
        if (x.toolbar.length > 0)
            for (i in x.toolbar) {
                x.toolbar[i].options = onToolbarOption($, x.toolbar[i])
                e.toolbarOptions.items.push(x.toolbar[i])
            }
    // e.toolbarOptions.items.push()
}
export const onToolbarOption = ($, e) => {
    e.options.onClick = () => onToolbarClick($, e)
    return e.options
}
export const onToolbarClick = ($, e) => {
    switch (e.event.split("-")[0].toUpperCase()) {
        case "BDO":
            return bdoToolbarClick($, e)
    }
}
export const onShowPopup = (popup, option, i) => {
    popup.beginUpdate()
    if (option.visible === undefined) option.visible = true
    for (i in option) popup.option(i, option[i])
    popup.endUpdate()
    return popup
}
export const onDropDownTemplate = ($, e, tmp, value, xDataGrid, dataGrid) => {
    value = e.component.option("value")
    xDataGrid = window.$("<div>").dxDataGrid({
        dataSource: e.component.getDataSource(),
        columns: tmp.columns,
        hoverStateEnabled: true,
        paging: { enabled: true, pageSize: 10 },
        filterRow: { visible: true },
        scrolling: { mode: "virtual" },
        selection: { mode: "single" },
        selectedRowKeys: [value],
        height: "100%",
        onSelectionChanged: (selectedItems, keys, hasSelection) => {
            keys = selectedItems.selectedRowKeys
            hasSelection = keys.length
            e.component.option("value", hasSelection ? keys[0] : null)
        }
    })
    dataGrid = xDataGrid.dxDataGrid("instance")

    e.component.on("valueChanged", (args) => {
        dataGrid.selectRows(args.value, false)
        e.component.close()
    })

    return xDataGrid
}
export const onToolbar = ($, e, x) => {
    e = [
        { name: "addRowButton", cssClass: "btn-new-row" },
        { name: "applyFilterButton" },
        { name: "columnChooserButton" },
        { name: "revertButton" },
        { name: "saveButton" },
        { name: "searchPanel" },
        { name: "exportButton" },
        { name: "groupPanel" }
    ]
    e.push({
        location: "before",
        widget: "dxButton",
        options: {
            icon: "refresh",
            onClick: () => onRefresh($)
        }
    })
    if ($.access.includes("import"))
        e.push({
            location: "before",
            template: "importExcel"
        })
    x = $.fn.config($.flag)
    if (x.maxDate !== undefined && x.minDate !== undefined) {
        if ($.minDate === undefined) $.minDate = x.minDate
        if ($.maxDate === undefined) $.maxDate = x.maxDate
        e.push({
            location: "before",
            widget: "dxDateBox",
            options: {
                value: $.minDate,
                width: 125,
                displayFormat: "dd MMM yyyy",
                onValueChanged: (a) => ($.minDate = a.value),
                onInitialized: (a) => console.log(a)
            }
        })
        e.push({
            location: "before",
            widget: "dxDateBox",
            options: {
                value: $.maxDate,
                width: 125,
                displayFormat: "dd MMM yyyy",
                onValueChanged: (a) => ($.maxDate = a.value)
            }
        })
        e.push({
            location: "before",
            widget: "dxButton",
            options: { icon: "filter", onClick: () => onFilterDate($) }
        })
    }
    return e
}
export const onToolbarDetail = ($, p, e, x, i, j) => {
    e = [
        { name: "addRowButton", cssClass: "btn-new-" + p.css },
        { name: "applyFilterButton" },
        { name: "columnChooserButton" },
        { name: "revertButton" },
        { name: "saveButton" },
        { name: "searchPanel" },
        { name: "exportButton" },
        { name: "groupPanel" }
    ]
    if (p.onToolbarPreparing === undefined && p.onToolbar !== undefined) p.onToolbarPreparing = p.onToolbar
    if (p.onToolbarPreparing !== undefined)
        for (i in p.onToolbarPreparing) {
            if (typeof p.onToolbarPreparing[i] === "string")
                switch (p.onToolbarPreparing[i]) {
                    case "is_return":
                        e.push({
                            location: "before",
                            widget: "dxCheckBox",
                            width: 100,
                            options: {
                                text: "Return",
                                value: $.old.data.is_return,
                                onValueChanged: (a) => {
                                    $.old.data.is_return = a.value
                                }
                            }
                        })
                        break
                }
            else
                switch (i) {
                    case "pull":
                        if ($.old.key === null) for (j in p.onToolbarPreparing[i]) e.push(onPull($, p.onToolbarPreparing[i][j], p))
                        break
                }
        }
    return e
}
export const onPull = ($, p, x, btn) => {
    btn = {
        location: "before",
        widget: "dxButton",
        options: {
            text: p.title,
            elementAttr: { class: "btn-toolbar" },
            onClick: (e, i) => {
                e = {
                    data: {},
                    tmp: null
                }
                for (i in p.check) {
                    e.tmp = $.grid.cellValue($.old.index, p.check[i])
                    if (e.tmp !== undefined && e.tmp !== null) {
                        if (typeof e.tmp === "string") {
                            if (e.tmp !== "") e.data[p.check[i]] = e.tmp
                        } else {
                            e.data[p.check[i]] = e.tmp
                        }
                    }
                    if (e.data[p.check[i]] === undefined) return
                }
                PostData({
                    url: $.fn.url("pull"),
                    showLoading: true,
                    data: { check: e.data, key: $.old.key, target: p.target, name: x.name },
                    success: (res, d) => {
                        d = $.details[x.name]
                        d.grid.option("dataSource", (d.dataSource = res.detail))
                        if (typeof d.onSaved !== "undefined") eval(d.onSaved)
                    }
                })
            }
        }
    }
    if (p.shortcut !== undefined) btn.options.accessKey = p.shortcut.toLowerCase()
    return btn
}
export const onContentReady = ($, e, x) => {
    setTimeout(() => {
        onClearAccessKey($)
            .$("#pg-" + $.flag)
            .find(".btn-new-row>.dx-item-content>.dx-button")
            .attr("accesskey", "n")
    })
}
export const onContentReadyDetail = ($, e, x) => {
    setTimeout(() => {
        onClearAccessKey($, "")
            .$("#pg-" + $.flag)
            .find(".btn-new-" + e.css + ">.dx-item-content>.dx-button")
            .attr("accesskey", e.accesskey)
        onContentReadyPopup($)
    })
}
export const onClearAccessKey = ($, extra) => {
    if (extra === undefined) extra = ", .btn-save"
    window.$(".btn-new-row>.dx-item-content>.dx-button, .btn-save-detail" + extra).removeAttr("accesskey")
    return window
}
export const onContentReadyPopup = ($, e, x) => {
    setTimeout(() => {
        window.$(".btn-save").removeAttr("accesskey")
        window
            .$("#pop-" + $.flag)
            .find(".btn-save")
            .attr("accesskey", "s")
    })
}
export const onContentReadyPopupDetail = ($, e, x) => {
    setTimeout(() => {
        onClearAccessKey($)
            .$("#pop-detail-" + $.flag)
            .find(".btn-save-detail")
            .attr("accesskey", "s")
    })
}
export const onFilterDate = ($) => {
    PostData({
        url: $.fn.url("filter"),
        showLoading: true,
        data: { minDate: $.minDate, maxDate: $.maxDate, filter: $.filter ?? {} },
        success: (res) => {
            $.grid.option("dataSource", res.data)
        }
    })
}
export const onRefresh = ($, p, x, i) => {
    if (p === undefined) p = {}
    if (p.grid !== undefined) {
        $.grid = p.grid
        if (p.isDefault) settingEditing($)
    }
    if (p.showLoading === undefined) p.showLoading = true

    x = {}
    for (i in $.collection) {
        x.flag = $.collection[i]
        x.data = $.fn.data(i) ?? []
        if (x.data.length > 0) {
            x.data.sort((a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at))
            x.temp = Date.parse($.collection[i] ?? "1900-01-01 00:00:00")
            if (isNaN(x.temp)) x.temp = 0
            if (x.temp < Date.parse(x.data[0].updated_at)) $.collection[i] = x.data[0].updated_at
        }
        if ($.collection[i] === undefined) $.collection[i] = "1900-01-01 00:00:00"
    }
    // setting sini
    if (!p.showLoading) onResetColumn($)
    else
        PostData({
            url: $.fn.url("get"),
            data: $.collection,
            showLoading: p.showLoading,
            success: (result, e, x, old, ds, config) => {
                for (i in result) {
                    e = result[i]
                    old = $.fn.data(i)
                    x = { data: [...old].filter((expr) => expr.updated_at !== null && expr.updated_at !== undefined), i: 0, j: 0, k: 0 }
                    if (e.deleted !== undefined) {
                        if (e.deleted.length > 0)
                            if (Date.parse($.collection[i]) < Date.parse(e.deleted[0].deleted_at)) $.collection[i] = e.deleted[0].deleted_at
                        for (x.i in e.deleted) {
                            x.getChanges = x.data.filter((expr) => expr.id === e.deleted[x.i].table_id)
                            if (x.getChanges.length > 0) for (x.j in x.getChanges) x.data.splice(x.data.indexOf(x.getChanges[x.j]), 1)
                        }
                    }
                    if (e.dataSource !== undefined) {
                        if (e.dataSource.length > 0) if (Date.parse($.collection[i]) < Date.parse(e.dataSource[0])) $.collection[i] = e.dataSource[0]
                        for (x.i in e.dataSource) {
                            x.getChanges = x.data.filter((expr) => expr.id === e.dataSource[x.i].id)
                            if (x.getChanges.length > 0)
                                for (x.j in e.dataSource[x.i]) for (x.k in x.getChanges) x.getChanges[x.k][x.j] = e.dataSource[x.i][x.j]
                            else x.data.push(e.dataSource[x.i])
                        }
                    }
                    switch (i) {
                        case "item_unit":
                            x.dataItem = [...$.fn.data("item")]
                            for (x.i in x.dataItem)
                                x.data.splice(0, 0, {
                                    id: x.dataItem[x.i].base_unit_id + "/" + x.dataItem[x.i].id,
                                    item_id: x.dataItem[x.i].id,
                                    multiplier: 1,
                                    to_unit_id: x.dataItem[x.i].base_unit_id,
                                    is_active: x.dataItem[x.i].is_active
                                })

                            break
                    }
                    $.fn.data(i, x.data)
                    if (i === $.flag) $.grid.option("dataSource", x.data)
                }
                onResetColumn($)
                ds = $.grid.option("dataSource")
                config = $.fn.config($.flag)
                if (config.isFilterOnLoad === undefined || config.isFilterOnLoad === null) config.isFilterOnLoad = true
                if ((($.maxDate !== undefined && $.minDate !== undefined) || x.filter !== undefined) && p.showLoading && config.isFilterOnLoad)
                    onFilterDate($)
            },
            warning: (e) => {
                if ($.grid.option("onToolbarPreparing") === undefined || $.grid.option("onToolbarPreparing") === null)
                    $.setState({ warning: e.message })
                else window.toastr.warning(e.message)
            },
            error: (e) => {
                if ($.grid.option("onToolbarPreparing") === undefined || $.grid.option("onToolbarPreparing") === null)
                    $.setState({ warning: e.message })
                else window.toastr.error(e.message)
            }
        })
    return $.grid
}
export const onResetColumn = ($, e) => {
    e = $.fn.config($.flag)
    if (e === undefined || e === null) return
    e.columns = settingColumn($, e.columns, e)
    $.fn.column($.flag, e.columns)
    $.fn.config($.flag, e)
    $.grid.beginUpdate()
    $.grid.option("columns", e.columns)
    $.grid.option("onToolbarPreparing", (e) => onToolbarPreparing($, e))
    $.grid.endUpdate()
    setTimeout(() => onContentReady($))
}
export const onExporting = ($, e, x) => {
    x = { columns: $.grid.option("columns"), i: 0 }
    if (e.format.toUpperCase() === "EXCEL" || e.format.toUpperCase() === "XLSX") {
        if (x.columns !== undefined) {
            x.columns = { ...x.columns }
            x.group = {}
            for (x.i in x.columns) {
                x.columns[x.i].title = x.columns[x.i].caption
                x.columns[x.i].caption = x.columns[x.i].dataField
                x.columns[x.i].hidden = !(x.columns[x.i].visible === undefined ? true : x.columns[x.i].visible)
                if (x.columns[x.i].dataType === "image") {
                    x.columns[x.i].visible = false
                } else {
                    x.columns[x.i].visible = true
                }
                if (x.columns[x.i].lookup !== undefined) {
                    x.columns[x.i].lookup.flag = x.columns[x.i].lookup.displayExpr
                    x.columns[x.i].lookup.displayExpr = x.columns[x.i].lookup.valueExpr
                }
                if (x.columns[x.i].groupIndex !== undefined && x.columns[x.i].groupIndex !== undefined) {
                    x.group[x.columns[x.i].dataField] = x.columns[x.i].groupIndex
                    x.columns[x.i].groupIndex = undefined
                }
                console.log(x.columns[x.i])
            }
            $.exporting = { e: e, x: x }
            $.grid.option("columns", x.columns)
        } else {
            onExport($, e, x)
        }
        e.cancel = true
    }
}
export const onExport = ($, e, x) => {
    $.exporting = undefined
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet($.flag)
    exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/octet-stream" }), $.flag + ".xlsx")
            if (x.columns !== undefined) {
                for (x.i in x.columns) {
                    x.columns[x.i].caption = x.columns[x.i].title
                    x.columns[x.i].visible = !x.columns[x.i].hidden
                    if (x.columns[x.i].lookup !== undefined) x.columns[x.i].lookup.displayExpr = x.columns[x.i].lookup.flag
                    if (x.group[x.columns[x.i].dataField] !== undefined) x.columns[x.i].groupIndex = x.group[x.columns[x.i].dataField]
                }
                $.grid.option("columns", x.columns)
            }
        })
    })
}
export const onDateToString = (value, output) => {
    output =
        value.getFullYear() +
        "-" +
        ("0" + (value.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + value.getDate()).slice(-2) +
        " " +
        ("0" + value.getHours()).slice(-2) +
        ":" +
        ("0" + value.getMinutes()).slice(-2) +
        ":" +
        ("0" + value.getSeconds()).slice(-2)
    return output
}
export const onSaved = ($, e, x, i) => {
    $.continueSave = false
    onRefresh($)
    x = { config: $.fn.config($.flag) }
    if (typeof x.config.onSaved !== undefined)
        switch (x.config.onSaved) {
            case "calculate":
                x.data = e.changes.filter((expr) => expr.type !== "remove")
                if (x.data.length > 0) {
                    x.data = x.data.filter((expr) => (expr.data.item_id ?? "") === "")
                    if (x.data.length > 0) {
                        x.data = x.data[0].data
                        x.item = $.fn.data("item")
                        x.mx = x.item.length - 1
                        x.loop = (nx) => {
                            if (nx === undefined) nx = 0
                            else nx += 1
                            x.p = Math.floor((nx / x.mx) * 100)
                            PostData({
                                url: $.fn.url("process_calculate"),
                                data: { date: x.data.date, item_id: x.item[nx].id },
                                showLoading: true,
                                loadingText:
                                    "<div class=progress><div class=progress-bar role=progressbar style=width:" + x.p + "%;>" + x.p + "%</div></div>",
                                success: (e) => {
                                    if (nx < x.mx) x.loop(nx)
                                }
                            })
                        }
                        x.loop()
                    }
                }
                break
        }
    // window.loading({
    //     text: '<div class=progress><div class=progress-bar role=progressbar style=width:35%;>35%</div></div>'
    // })
    // for (i in $.details) $.details[i].dataSource = []
}
export const onSaving = ($, e, x, isValid, i, j, old) => {
    if ($.continueSave) return
    else $.continueSave = true
    isValid = true
    x = { config: $.fn.config($.flag), mode: $.grid.option("editing").mode, skipAlert: false }
    if (x.config.validate !== undefined) {
        x.validate = ($, e, x) => eval(x.config.validate)
        x.validate($, e, x)
    }
    if (e.changes.length > 0 && x.mode === "popup") if (e.changes[0].type === "remove") x.skipAlert = true
    for (i in e.changes)
        if (e.changes[i].data !== undefined)
            for (j in e.changes[i].data)
                if (typeof e.changes[i].data[j].getMonth === "function") e.changes[i].data[j] = onDateToString(e.changes[i].data[j])

    if (isValid)
        if ((x.mode === "batch" || x.mode === "popup") && !x.skipAlert)
            confirm("<i>Are you sure?</i>", "Confirm changes").then((res) => (res ? onUpload($, e) : ($.continueSave = false)))
        else onUpload($, e)
    else $.continueSave = false

    e.cancel = true
}
export const onUpload = ($, e, help, i, j) => {
    help = { details: null, key: null }
    if ($.details !== undefined && $.details !== null)
        for (i in $.details)
            if ($.details[i].dataSource !== undefined)
                if ($.details[i].dataSource.length > 0) {
                    help.detail = $.details[i]
                    help.temp = [...help.detail.dataSource]
                    if (help.detail.skip !== undefined) {
                        for (j in help.detail.skip)
                            help.temp = help.temp.filter((expr, pot, res, k) => {
                                pot = j.split("|")
                                res = false
                                for (k in pot)
                                    if (+(expr[pot[k]] ?? help.detail.skip[j]) !== help.detail.skip[j]) {
                                        res = true
                                        break
                                    }
                                return res
                            })
                        // if (help.detail.skip.zero !== undefined)

                        // if (help.detail.skip.empty !== undefined)
                        //     for (j in help.detail.skip.empty)
                        //         help.temp = help.temp.filter((expr, pot, res, k) => {
                        //             pot = help.detail.skip.zero[j].split("|")
                        //             res = false
                        //             for (k in pot)
                        //                 if ((expr[pot[k]] ?? "") !== "") {
                        //                     res = true
                        //                     break
                        //                 }
                        //             return res
                        //         })
                    }
                    if (help.temp.length > 0) {
                        if (help.details === null) help.details = {}
                        help.details[i] = help.temp
                    }
                }
    if ($.old !== undefined && $.old !== null) help.key = $.old.key
    PostData({
        url: $.fn.url("save"),
        data: { data: e.changes, details: help.details, key: help.key, extra: $.extra },
        showLoading: true,
        success: (result) => {
            if (typeof result !== "object") result = { temp: result }
            result.i = 0
            for (result.i in result.update) {
                result.getChanges = e.changes.filter((expr) => expr.type === "insert" && expr.key === result.update[result.i].key)
                for (result.j in result.getChanges) {
                    result.getChanges[result.j].type = "update"
                    result.getChanges[result.j].key = result.update[result.i].id
                }
            }
            for (result.i in result.insert) {
                result.getChanges = e.changes.filter((expr) => expr.type === "insert" && expr.key === result.insert[result.i].key)
                for (result.j in result.getChanges)
                    for (result.k in result.insert[result.i])
                        if (result.k !== "key") result.getChanges[result.j].data[result.k] = result.insert[result.i][result.k]
            }
            result.i = 0
            for (result.i in result.cancelUpdate) {
                result.getChanges = e.changes.filter((expr) => expr.type === "update" && expr.key === result.cancelUpdate[result.i])
                for (result.j in result.getChanges) result.getChanges[result.j].type = "remove"
            }
            $.grid.saveEditData()
            console.log(result.call)
            if (Array.isArray(result.call))
                for (result.i in result.call)
                    switch (result.call[result.i]) {
                        case "onFilterDate":
                            onFilterDate($)
                            break
                    }
        },
        error: (e) => {
            $.continueSave = false
            if (e.response === undefined) onMessage("There's something went wrong<br />please contact us for more information", $.info.name)
            else onMessage(e.response.data.message, $.info.name)
        },
        warning: (e) => {
            $.continueSave = false
            onMessage(e.message, $.info.name)
        }
    })
}
export const onMessage = (message, title) => {
    custom({ messageHtml: '<div class="text-center">' + message + "<div>", title: title }).show()
}
export const onDmy = (date, x) => {
    x = {}
    x.month = +date.getMonth() + 1
    if (x.month < 10) x.month = "0" + x.month
    x.date = +date.getDate()
    if (x.date < 10) x.date = "0" + x.date
    return date.getFullYear() + "-" + x.month + "-" + x.date
}
export const onInitNewRow = ($, e, x, i) => {
    for (i in $.details) $.details[i].dataSource = []
    $.old = { data: e.data, key: null, index: 0 }
    x = { config: $.fn.config($.flag) }
    if (x.config.toggleEditing !== undefined) x.columns = updateColumn($, e.component, x.config.toggleEditing, { allowEditing: true })
    $.selectedGroup = {}
    setTimeout(() => {
        if (x.columns !== undefined)
            if (Array.isArray(x.columns.columns))
                if (x.columns.columns.filter((expr) => expr.dataField === "date").length > 0) $.grid.cellValue(0, "date", onDmy(new Date()))
    })
    return $.old
}
export const onEditingStart = ($, e, x, i) => {
    $.old = { data: e.data, key: e.key, index: e.component.getRowIndexByKey(e.key) }
    x = { config: $.fn.config($.flag) }
    if (x.config.toggleEditing !== undefined) x.columns = updateColumn($, e.component, x.config.toggleEditing, { allowEditing: false })
    $.selectedGroup = {}
    return $.old
}
export const onDetailStart = ($, e, x, i) => {
    x = { data: e.data }
    x.key = e.key === undefined ? null : e.key
    x.index = e.index === undefined ? 0 : e.component.getRowIndexByKey(e.key)
}
export const updateColumn = ($, grid, columnNames, options, x, i, key) => {
    x = { columns: $.fn.column($.flag) }
    if (typeof columnNames === "string") columnNames = [columnNames]
    for (i in columnNames) {
        for (key in options) {
            x.temp = x.columns.filter((expr) => expr.dataField === columnNames[i])
            if (x.temp.length > 0) if (x.temp[0] !== undefined) x.temp[0][key] = options[key]
        }
    }
    $.fn.column($.flag, x.columns)
    grid.option("columns", x.columns)
    return { grid: grid, columns: x.columns }
}
export const onToolbarPreparingTreeUser = ($, e, data) => {
    console.log(JSON.stringify(data))
    if (data.email !== undefined) {
        e.toolbarOptions.items.push({
            location: "before",
            widget: "dxTextBox",
            options: {
                width: 210,
                value: data.email,
                onValueChanged: (x) => {
                    data.email = x.value
                }
            }
        })
        e.toolbarOptions.items.push({
            location: "before",
            widget: "dxTextBox",
            options: {
                width: 210,
                value: "",
                mode: "password",
                onValueChanged: (x) => {
                    data.password = x.value
                }
            }
        })
    }
    e.toolbarOptions.items.push({
        location: "before",
        widget: "dxButton",
        options: {
            icon: "save",
            onClick: (x) => onSaveAccessUser($, x, data)
        }
    })
}
export const onSaveAccessUser = ($, x, data, i) => {
    if (data.email === "") return window.toastr.warning($.__.require.email)
    x = { data: $.tree.user.getSelectedRowsData("all"), selected: [] }
    for (i in x.data) {
        x.row = x.data[i]
        x.topParent = null
        if (x.row.id !== null && x.row.id !== undefined && x.row.id !== "")
            if (
                x.selected.filter(function (expr) {
                    return expr === x.row.id
                }).length <= 0
            ) {
                x.selected.push(x.row.id)
                x.topParent = x.row.id.split(".")
                if (x.topParent.length <= 1) x.topParent = null
                else x.topParent = x.topParent[0]
            }
        if (x.row.parent !== null && x.row.parent !== undefined && x.row.parent !== "") {
            if (
                x.selected.filter(function (expr) {
                    return expr === x.row.parent
                }).length <= 0
            )
                x.selected.push(x.row.parent)
            if (x.topParent !== x.row.parent)
                if (
                    x.selected.filter(function (expr) {
                        return expr === x.topParent
                    }).length <= 0
                )
                    x.selected.push(x.topParent)
        }
    }
    if (x.selected.length <= 0) return window.toastr.warning($.__.require.selected)
    PostData({
        url: $.fn.url("access"),
        data: { id: data.id, email: data.email, password: data.password, data: x.selected },
        showLoading: true,
        success: (result) => {
            window.toastr.success($.__.success)
            if (result.isRefresh) onRefresh($)
            $.popup.user.hide()
        }
    })
}
export const onSelectionChanged = ($, key, e, i, x, selected) => {
    if (e === undefined) e = $.fn.config($.flag)
    if (e.masterDetail === undefined) return
    x = { collection: e.masterDetail.data }
    if (typeof x.collection === "object")
        if (i === undefined)
            for (i in x.collection) {
                x.data = x.collection[i]
                break
            }
        else x.data = x.collection[i]
    else return
    if (x.data === undefined) return
    selected = $.grid.getSelectedRowKeys()
    if (x.data === null) x.data = {}
    if (x.data[key] !== undefined) {
        if (selected.includes(key)) x.data[key].grid.selectAll()
        else x.data[key].grid.deselectAll()
    }
}
