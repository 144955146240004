import { Chart, DataGrid, PieChart } from "devextreme-react"
import { Column, Item, Summary, Toolbar, TotalItem } from "devextreme-react/data-grid"
import { CommonSeriesSettings, Connector, Label, Legend, Series, SeriesTemplate } from "devextreme-react/chart"
import React from "react"

export class Omzet extends React.Component {
    constructor(props) {
        super(props)
        this.$ = props.parent
        this.fn = props.fn
        this.state = { ...props }
    }
    componentDidMount() {}
    onRefresh = () => {}
    render($, x) {
        // return <></>
        $ = this.$
        x = this.state.data.omzet
        return (
            <div className="row mx-0 mb-3">
                {$.info.access.includes("omzet") ? (
                    <div className="col-md-12">
                        <Chart
                            dataSource={x.data}
                            title={x.title}
                            width={"100%"}
                            height={"100%"}
                            elementAttr={{ style: "overflow-x: hidden" }}
                            resolveLabelOverlapping="shift"
                            palette={"Bright"}
                        >
                            <CommonSeriesSettings
                                argumentField={"month_year"}
                                type={"bar"}
                                valueField={x.cols.length === 1 ? x.cols[0] : undefined}
                                ignoreEmptyPoints={true}
                            >
                                <Label visible={true} customizeText={(e) => e.value.toLocaleString("en-US")}>
                                    <Connector visible={true} width={1} />
                                </Label>
                            </CommonSeriesSettings>
                            {x.cols.map((e) => (x.cols.length > 1 ? <Series valueField={e} name={e} /> : <SeriesTemplate nameField={x.cols[0]} />))}
                            <Legend visible={x.cols.length > 1} />
                        </Chart>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        )
    }
}
